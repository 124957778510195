@import url(https://fonts.googleapis.com/css2?family=Antic+Didone&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.landing-screen {
  display: flex;
}

.landing-container {
  display: flex;
  flex-flow: column;
  margin: 0 auto;
}

.landing-container span {
  height: 2px;
}

@keyframes neon {
  from {
    text-shadow: 0 0 2px #3dd2cc, 0 0 10px #3dd2cc, 0 0 15px #3dd2cc, 0 0 20px #3e6b89, 0 0 25px #3e6b89, 0 0 30px #3e6b89, 0 0 35px #3e6b89;
  }
  to {
    text-shadow: 0 0 12px #000, 0 0 25px #3dd2cc, 0 0 35px #3dd2cc, 0 0 40px #3e6b89, 0 0 45px #3e6b89, 0 0 50px #3e6b89, 0 0 50px #3e6b89;
  }
}

@keyframes orbs {
  from {
  }
  to {
  }
}

.landing-name {
  margin: 100px auto 0px auto;
  font-family: "Antic Didone", serif;
  font-size: 40px;
  letter-spacing: 5px;
  color: whitesmoke;
  top: 30px;
}

.landing-title {
  margin: 0 auto;
  font-family: "Antic Didone", serif;
  font-size: 20px;
  font-style: italic;
  letter-spacing: 4px;
}

.landing-button {
  height: 40px;
  font-family: "Antic Didone", serif;
  font-weight: 700;
  background-color: #3e6b89;
  border-radius: 5px;
  color: whitesmoke;
  display: inline-block;
  border-radius: 4px;
  border: none;
  text-align: center;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  font-size: 10px;
  width: 140px;
}

.landing-button:active {
  transform: scale(0.95);
  outline: none;
}

.landing-button-link {
  margin: 15px auto;
}

.landing-photo {
  width: 200px;
  border-radius: 50%;
  margin: 0 auto;
}

.about-me-container {
  display: flex;
  flex-flow: column;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
}

.photo {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
  transition: 0.3s ease;
}

.about-me-info {
  display: flex;
  flex-flow: column;
  margin: 10px auto;
  border-radius: 5px;
  padding: 2vw;
  transition: 0.5s ease;
}

.about-me-logos {
  display: flex;
  flex-flow: row wrap;
  margin: 5px auto;
  font-size: 30px;
}

.about-me-logos-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 auto;
  width: 210px;
  padding: 5px;
  border-radius: 5px;
}

.about-me-text {
  font-size: 10px;
  padding: 8px;
  text-align: center;
}

i {
  margin: 3px 5px;
}

.about-me-button {
  width: 70px;
  margin: 0 auto;
  text-decoration: none;
  color: white;
  padding: 5px 5px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 700;
  background-color: #3e6b89;
  cursor: pointer;
}

.about-me-button:active {
  transform: scale(0.95);
  outline: none;
}

.about-me-button-link {
  width: 70px;
  margin: 0 auto;
}

.contact-screen {
  display: flex;
}

.contact-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: 60px auto;
}

.contact-text {
  margin: 30px auto 15px auto;
  text-align: center;
}

.contact-text-2 {
  margin-bottom: 30px;
}

.contact-links {
  display: flex;
  margin-top: 20px;
}

.contact-icons {
  margin: 10px;
  color: whitesmoke;
}

.contact-icons:hover {
  cursor: pointer;
}

.form-container {
  background-color: transparent;
  width: 310px;
  height: 340px;
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: 2px 2px 2px 2px #3e6b89;
  border: 2px solid #3dd2cc;
}

.contact-form {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.text-input {
  margin-top: 10px;
  width: 280px;
  height: 30px;
}

.text-area {
  margin-top: 10px;
  width: 280px;
  height: 200px;
}

.form-buttom {
  margin: 5px auto;
  padding: 3px;
  border: 2px solid #3e6b89;
  border-radius: 5px;
  font-size: 15px;
  color: whitesmoke;
  background-color: #3e6b89;
  font-weight: 700;
}

.form-buttom:active {
  transform: scale(0.9);
}

.form-buttom:hover {
  cursor: pointer;
}

.contact-photo {
  margin-top: 25px;
  width: 180px;
  height: 180px;
  border-radius: 50%;
}

.nav-container {
  display: flex;
  position: fixed;
  top: 0;
  z-index: 999;
  justify-content: space-between;
  align-items: center;
  background-color: #3e6b89;
  width: 100%;
  height: 70px;
}

.logo {
  width: 50px;
  margin-left: 5vw;
}

.menu-btn {
  position: inherit;
  right: 1rem;
  top: 1rem;
  height: 20px;
  width: 28px;
  cursor: pointer;
  transition: all .5s ease-in-out;
}

.menu-btn-burger {
  position: absolute;
  right: 0;
  top: .5rem;
  width: 28px;
  height: 3px;
  background: whitesmoke;
  transition: all .5s ease-in-out;
}

.menu-btn-burger::before {
  content: '';
  position: absolute;
  top: -8px;
  width: 28px;
  height: 3px;
  background: whitesmoke;
  transition: all .5s ease-in-out;
}

.menu-btn-burger::after {
  content: '';
  position: absolute;
  top: 8px;
  width: 20px;
  height: 3px;
  background: whitesmoke;
  transition: all .5s ease-in-out;
}

.menu-btn-burger.open {
  transform: rotate(720deg);
  background: transparent;
}

.menu-btn-burger.open::before {
  transform: rotate(45deg) translate(5px, 8px);
}

.menu-btn-burger.open::after {
  width: 28px;
  transform: rotate(-45deg) translate(3px, -7px);
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 80vw;
  visibility: hidden;
}

.nav.open {
  visibility: visible;
}

.nav .menu-nav {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  background: #3e6b89;
  list-style-type: none;
  padding-right: 1rem;
  transform: translateY(-100%);
  transition: all .5s ease-in-out;
}

.nav .menu-nav.open {
  transform: translateY(0);
}

.nav .menu-nav-item {
  transform: translateX(65vw);
  transition: all .5s ease-in-out;
}

.nav .menu-nav-item.open {
  transform: translateX(0);
}

.nav .menu-nav-item.active > Link {
  color: #3e6b89;
}

.nav .menu-nav-link {
  display: inline-block;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 1rem 0;
  font-weight: 300;
  text-decoration: none;
  color: whitesmoke;
  transition: all .5s ease-in-out;
}

.nav .menu-nav-link:hover {
  color: #3dd2cc;
}

.menu-nav-item:nth-child(1) {
  transition-delay: 0.25s;
}

.menu-nav-item:nth-child(2) {
  transition-delay: 0.35s;
}

.menu-nav-item:nth-child(3) {
  transition-delay: 0.45s;
}

.menu-nav-item:nth-child(4) {
  transition-delay: 0.55s;
}

.menu-nav-item:nth-child(5) {
  transition-delay: 0.65s;
}

.sidenav-container {
  visibility: hidden;
  width: 0;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.sidenav-logo {
  width: 80px;
}

.sidenav-link {
  text-decoration: none;
  margin: 10px 0;
  color: whitesmoke;
}

.sidenav-link:hover {
  transition: all .5s ease-in-out;
  color: black;
}

.sidenav-icons-container {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin-top: 10px;
}

.sidenav-icons {
  margin: 3px;
  color: whitesmoke;
  text-decoration: none;
}

.sidenav-icons:hover {
  transition: all .5s ease-in-out;
  cursor: pointer;
  color: black;
}

.portfolio-screen {
  display: flex;
}

.projects-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
}

.project-container {
  display: flex;
  flex-flow: column;
  border-radius: 15px;
  width: 250px;
  height: 200px;
  margin: 20px;
  box-shadow: 2px 2px 0px 0px #3dd2cc;
  background: #3e6b89;
  perspective: 1000px;
  opacity: 0.7;
  border-radius: 15px;
}

.project-name {
  text-align: center;
  margin: 5px;
}

.project-image {
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 200px;
  margin: 3px auto;
}

.project-links-containers,
.project-links-containers-back {
  display: flex;
  justify-content: center;
  position: relative;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  margin-bottom: 5px;
}

.project-link,
.project-link-back {
  display: flex;
  flex-flow: row;
  align-items: center;
  text-decoration: none;
  color: whitesmoke;
  border: 2px solid #3dd2cc;
  border-radius: 5px;
  background: transparent;
  transition: transform 0.5s ease-out;
  margin: auto 8px;
  padding: 0px 4px;
  font-size: 8px;
}

.project-link:hover,
.project-link-back:hover {
  color: #3dd2cc;
  transition: 0.8s;
}

.project-link:hover .fas.fa-globe,
.project-link-back:hover .fas.fa-globe {
  color: #3dd2cc;
  transition: all .5s ease-in-out;
}

.project-link:hover .fab.fa-github,
.project-link-back:hover .fab.fa-github {
  color: #3dd2cc;
  transition: all .5s ease-in-out;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-back {
  display: flex;
  align-items: center;
  padding: 0 5px;
  color: whitesmoke;
  font-size: 12px;
  transform: rotateY(180deg);
}

.project-link-back {
  transform: rotateY(180deg);
}

@media only screen and (min-width: 541px) {
  .nav-container {
    visibility: hidden;
  }
  .sidenav-container {
    visibility: visible;
    width: 125px;
    padding: 10px;
  }
  .landing-container {
    margin-top: 10px;
  }
  .landing-name {
    font-size: 60px;
    margin: 10px auto;
  }
  .landing-title {
    font-size: 25px;
  }
  .about-me-text {
    font-size: 15px;
    width: 400px;
  }
  .about-me-logos-container {
    width: 300px;
  }
  .project-container {
    width: 300px;
    height: 265px;
  }
  .project-image {
    width: 250px;
  }
  .project-link, .project-link-back {
    margin: 18px;
    padding: 3px 4px;
    border-radius: 5px;
    font-size: 8px;
  }
  .landing-button {
    width: 170px;
    font-size: 15px;
  }
}

body {
  background-color: #4c90a2;
  height: 80vh;
  width: 100vw;
  font-family: "Antic Didone", serif;
  background-image: linear-gradient(to bottom, rgba(76, 144, 162, 0.85), rgba(76, 144, 162, 0.85)), url(/static/media/collage.4bb928a9.jpg);
  background-size: 100% 100%;
  background-repeat: repeat;
  color: whitesmoke;
  overflow-x: hidden;
}

